import './index.css'

import { extendTheme } from '@mui/joy'
import { CssVarsProvider } from '@mui/joy'
import { GoogleOAuthProvider } from '@react-oauth/google'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import ProtectedRoute from './pages/__components/ProtectedRoute'
import Dashboard from './pages/dashboard'
import Login from './pages/login'
import Play from './pages/play'
import reportWebVitals from './reportWebVitals'
import store, { persistor } from './store'

const customTheme = extendTheme({
  components: {
    JoyTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Satoshi',
        },
      },
    },
  },
})

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
    errorElement: <Login />,
  },
  {
    path: '/my-camp',
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: '/play',
    element: (
      <ProtectedRoute>
        <Play />
      </ProtectedRoute>
    ),
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CssVarsProvider
          defaultMode="light"
          disableTransitionOnChange
          theme={customTheme}
        >
          <GoogleOAuthProvider clientId="26816333104-b16s0i54cggcc80ev97p3prlbdbj7gg8.apps.googleusercontent.com">
            <RouterProvider router={router} />
          </GoogleOAuthProvider>
        </CssVarsProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
