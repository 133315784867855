import styled from '@emotion/styled'
// import { FestivalRounded } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  useColorScheme,
} from '@mui/joy'
import Cookies from 'js-cookie'
import { isEmpty, isNotEmpty } from 'ramda'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { updateUserProfile, updateWasRedirected } from '../../state/userState'
import { selectUserProfile } from '../../state/userStateSelectors'
import { IMenuItem } from '../../types'
import HeaderBar from '../__components/HeaderBar'
import CardsBox from './__components/cardsBox'
import PlayBox from './__components/playBox'
import ProfileBox from './__components/profileBox'
import ProfileCreationDialog from './__components/profileCreationDialog'
import { getUserProfile } from './api'
import { IGetUserProfileResult } from './types'

interface IGradientOverlayDivProps {
  colourScheme: string | undefined
}

const Dashboard = () => {
  const token = Cookies.get('token')

  const { mode: colourScheme, setMode } = useColorScheme()
  const selectedTheme = localStorage.getItem('selected-theme') as 'light' | 'dark'
  setMode(selectedTheme ?? 'light')

  const dispatch = useDispatch()
  const boxRef = useRef(null)

  const userProfile = useSelector(selectUserProfile)

  const [backgroundIndex, setBackgroundIndex] = useState<number>(
    Math.floor(Math.random() * 3) + 1,
  )
  const [showHeaderBar, setShowHeaderBar] = useState<boolean>(false)
  const [getUserProfileResult, setGetUserProfileResult] =
    useState<IGetUserProfileResult>({
      error: '',
      isFetched: false,
      userProfile: {},
    })

  useEffect(() => {
    dispatch(updateWasRedirected(true))
  }, [dispatch])

  useEffect(() => {
    if (token && isEmpty(userProfile)) {
      getUserProfile({ token, setGetUserProfileResult })
    }
  }, [token, userProfile])

  useEffect(() => {
    const { userProfile: userProfileResult } = getUserProfileResult

    if (isNotEmpty(userProfileResult)) {
      const {
        active,
        active_deck_id,
        avatar,
        current_language,
        id,
        user_id,
        username,
      } = userProfileResult

      dispatch(
        updateUserProfile({
          active,
          avatar,
          id,
          username,
          activeDeckId: active_deck_id,
          currentLanguage: current_language,
          userId: user_id,
        }),
      )
    }
  }, [dispatch, getUserProfileResult])

  useEffect(() => {
    const handleScroll = () => {
      const box = boxRef.current as HTMLDivElement | null

      if (!box) return

      const scrollTop = box.scrollTop

      if (scrollTop > 40) {
        setShowHeaderBar(true)
      } else {
        setShowHeaderBar(false)
      }
    }

    const box = boxRef.current as HTMLDivElement | null

    if (box) {
      box.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (box) {
        box.removeEventListener('scroll', handleScroll)
      }
    }
  }, [setShowHeaderBar])

  const isMobile = window.innerWidth < 900
  const menuItems: IMenuItem[] = []
  const { isFetched } = getUserProfileResult

  return (
    <Box
      ref={boxRef}
      bgcolor={colourScheme === 'light' ? '#b8c6db' : '#33658A'}
      height="100vh"
      width="100%"
      alignContent="flex-start"
      textAlign="center"
      overflow="auto"
      sx={{
        backgroundImage:
          colourScheme === 'light'
            ? 'linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%)'
            : 'linear-gradient(315deg, #33658A 0%, #170e13 74%)',
      }}
    >
      <CssBaseline />
      {isEmpty(userProfile) && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          height="100%"
          width="100%"
          zIndex="10"
          sx={{
            backdropFilter: 'blur(8px)',
          }}
        >
          <CircularProgress color="neutral" size="lg" variant="plain" />
        </Box>
      )}
      <HeaderBar
        colourScheme={colourScheme}
        currentPage={'/my-camp'}
        isMobile={isMobile}
        menuItems={menuItems}
        show={showHeaderBar}
      />
      <Box
        height="100%"
        sx={(theme) => ({
          [theme.breakpoints.down(900)]: {
            mt: 8,
          },
        })}
      >
        <Grid
          container
          columns={{ xs: 1, md: 3 }}
          alignItems="center"
          flexGrow={1}
          height="100%"
          px={isMobile ? 2 : 8}
        >
          <Grid xs={1} md={1} zIndex={1}>
            <Box
              p={2}
              m={1}
              bgcolor={colourScheme === 'light' ? '#fbfcfe' : '#202020'}
              borderRadius="md"
              boxShadow="md"
              zIndex={1}
              sx={(theme) => ({
                [theme.breakpoints.up(900)]: {
                  height: '512px',
                },
                [theme.breakpoints.up(1680)]: {
                  height: '600px',
                },
              })}
            >
              <ProfileBox userProfile={userProfile} />
            </Box>
          </Grid>
          <Grid xs={1} md={1} zIndex={1}>
            <Box
              p={2}
              m={1}
              bgcolor={colourScheme === 'light' ? '#fbfcfe' : '#202020'}
              borderRadius="md"
              boxShadow="md"
              zIndex={1}
              sx={(theme) => ({
                [theme.breakpoints.up(900)]: {
                  height: '512px',
                },
                [theme.breakpoints.up(1680)]: {
                  height: '600px',
                },
              })}
            >
              <PlayBox userProfile={userProfile} />
            </Box>
          </Grid>
          <Grid xs={1} md={1} zIndex={1}>
            <Box
              p={2}
              m={1}
              bgcolor={colourScheme === 'light' ? '#fbfcfe' : '#202020'}
              borderRadius="md"
              boxShadow="md"
              sx={(theme) => ({
                [theme.breakpoints.up(900)]: {
                  height: '512px',
                },
                [theme.breakpoints.up(1680)]: {
                  height: '600px',
                },
              })}
            >
              <CardsBox userProfile={userProfile} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ImageContainerDiv>
        <StyledImg
          src={require(
            `../../assets/images/my-camp-back-${backgroundIndex}.jpg`,
          )}
          alt="my-camp"
        />
        <GradientOverlayDiv colourScheme={colourScheme} />
      </ImageContainerDiv>
      {isEmpty(userProfile) && isFetched && (
        <ProfileCreationDialog token={token} />
      )}
    </Box>
  )
}

const ImageContainerDiv = styled.div`
  position: absolute;
  height: 100%;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 100%;
`

const StyledImg = styled.img`
  height: 100%;
  object-fit: cover;
  right: 0;
  position: absolute;
`

const GradientOverlayDiv = styled.div<IGradientOverlayDivProps>(
  ({ colourScheme }) => ({
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundImage:
      colourScheme === 'light'
        ? `linear-gradient(
    135deg,
    rgba(255, 255, 255, 1) 15%,
    rgba(184, 198, 219, 1) 30%,
    rgba(184, 198, 219, 1) 50%,
    rgba(245, 247, 250, 1) 65%,
    rgba(245, 247, 250, 0.8) 90%,
    rgba(245, 247, 250, 0.4) 100%
  )`
        : `linear-gradient(
    135deg,
    rgba(32, 32, 32, 1) 15%,
    rgba(51, 101, 138, 1) 30%,
    rgba(51, 101, 138, 1) 50%,
    rgba(62, 62, 62, 1) 65%,
    rgba(32, 32, 32, 0.8) 90%,
    rgba(32, 32, 32, 0.4) 100%
  )`,
    pointerEvents: 'none',
  }),
)

export default Dashboard
