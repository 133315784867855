import { Key, PasswordRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@mui/joy'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import Cookies from 'js-cookie'
import { isNotEmpty } from 'ramda'
import React, { FormEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { updateUserProfile } from '../../../state/userState'
import { login } from '../api'
import { IUserLoginResult, SignInFormElement } from '../types'
import { decodeJwtToken } from '../utils'

interface IProps {
  companyName: string
  toggleSignInForm: () => void
}

interface ILoginData {
  email: string
  password?: string
  persistent?: boolean
  isGoogle?: boolean
}

const SignIn = ({ companyName, toggleSignInForm }: IProps) => {
  const token = Cookies.get('token')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [userLoginResult, setUserLoginResult] = useState<IUserLoginResult>({
    error: '',
    tokenReceived: false,
    userProfile: {},
  })
  const [showPasswordResetDialog, setShowPasswordResetDialog] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')
  const [password, setPassword] = useState('')

  const isMobile = window.innerWidth <= 834

  useEffect(() => {
    if (token) {
      navigate('/my-camp')
    }
  }, [navigate, token])

  useEffect(() => {
    const { tokenReceived, userProfile } = userLoginResult

    if (tokenReceived) {
      if (userProfile && isNotEmpty(userProfile)) {
        const {
          active,
          active_deck_id,
          avatar,
          current_language,
          id,
          user_id,
          username,
        } = userProfile

        dispatch(
          updateUserProfile({
            active,
            avatar,
            id,
            username,
            activeDeckId: active_deck_id,
            currentLanguage: current_language,
            userId: user_id,
          }),
        )
      }
      navigate('/my-camp')
    }
  }, [dispatch, navigate, userLoginResult])

  const googleAuthResponse = (response: CredentialResponse) => {
    if (response.credential) {
      const decodedGoogleAuthToken = decodeJwtToken(response.credential)
      const googleAuthData: ILoginData = {
        email: decodedGoogleAuthToken.email,
        persistent: true,
        isGoogle: true,
      }

      login({ ...googleAuthData, setUserLoginResult })
    } else {
      googleAuthError()
    }
  }

  const googleAuthError = () => {
    setUserLoginResult({
      error: 'Google authentication failed',
      tokenReceived: false,
      userProfile: {},
    })
  }

  const { error: userLoginError } = userLoginResult

  return (
    <>
      <Stack
        gap={4}
        sx={(theme) => ({
          userSelect: 'none',
          [theme.breakpoints.up(1680)]: {
            mb: 2,
          },
        })}
      >
        <Stack gap={1}>
          <Typography component="h1" level="h3">
            Sign in
          </Typography>
          <Typography level="body-sm">
            New to {companyName}?{' '}
            <Link level="title-sm" onClick={toggleSignInForm}>
              Sign up!
            </Link>
          </Typography>
        </Stack>
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            colorScheme: 'light',
          }}
        >
          <GoogleLogin
            onSuccess={googleAuthResponse}
            onError={googleAuthError}
            locale="en"
            shape="rectangular"
            text="signin_with"
            theme={'outline'}
            width={isMobile ? 320 : 400}
          />
        </Box>
      </Stack>
      <Divider
        sx={() => ({
          color: { xs: '#FFF', md: 'text.tertiary' },
          userSelect: 'none',
        })}
      >
        or
      </Divider>
      <Stack
        gap={4}
        sx={(theme) => ({
          userSelect: 'none',
          [theme.breakpoints.up(1680)]: {
            mt: 2,
          },
        })}
      >
        <form
          onSubmit={(event: FormEvent<SignInFormElement>) => {
            event.preventDefault()
            const formElements = event.currentTarget.elements
            const signInData: ILoginData = {
              email: formElements.email.value,
              password: formElements.password.value,
              persistent: formElements.persistent.checked,
            }
            login({ ...signInData, setUserLoginResult })
          }}
        >
          <FormControl required>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              onChange={(event) => setEmailAddress(event.target.value)}
              autoFocus={!isMobile}
            />
          </FormControl>
          <FormControl required>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              name="password"
              onChange={(event) => setPassword(event.target.value)}
              startDecorator={<Key />}
            />
          </FormControl>
          {userLoginError && (
            <Typography color="danger" level="body-sm">
              {userLoginError}.
            </Typography>
          )}
          <Stack gap={4}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Checkbox size="sm" label="Remember me" name="persistent" />
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => setShowPasswordResetDialog(true)}
              >
                <Link level="title-sm">Forgot your password?</Link>
              </span>
            </Box>
          </Stack>
          <Button
            type="submit"
            fullWidth
            disabled={!emailAddress || !password}
            sx={{ userSelect: 'none', mt: 2 }}
          >
            Sign in
          </Button>
        </form>
      </Stack>
      <Modal open={showPasswordResetDialog}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          sx={{ backgroundColor: '#fbfcfe' }}
        >
          <DialogTitle>
            <PasswordRounded />
            Forgot Your Password?
          </DialogTitle>
          <Divider />
          <DialogContent>
            Don&apos;t worry!
            <br />
            Click the button below, and we will send a new password to your
            registered email address.
          </DialogContent>
          <Input
            type="email"
            name="reset-email"
            placeholder="Email address"
            value={emailAddress}
            onChange={(event) => setEmailAddress(event.target.value)}
            sx={{ my: 1 }}
          />
          <DialogContent>
            If you encounter any issues, please contact our support team at:
            <Link level="title-md" href="mailto: support@scraiku.com">
              support@scraiku.com
            </Link>
          </DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="primary"
              onClick={() => console.log('Reset Password')}
              disabled={!emailAddress}
            >
              Reset password
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setShowPasswordResetDialog(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  )
}

export default SignIn
